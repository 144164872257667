
/* ABRE E FECHA MENU MOBILE*/ 

const btnMobile = document.getElementById('btn-mobile');

function toggleMenu(event) {
  if (event.type === 'touchstart') event.preventDefault();
  const nav = document.getElementById('nav');
  nav.classList.toggle('active');
  const active = nav.classList.contains('active');
  event.currentTarget.setAttribute('aria-expanded', active);
  if (active) {
    event.currentTarget.setAttribute('aria-label', 'Fechar Menu');
  } else {
    event.currentTarget.setAttribute('aria-label', 'Abrir Menu');
  }
}

btnMobile.addEventListener('click', toggleMenu);
btnMobile.addEventListener('touchstart', toggleMenu);



/* FUNÇÃO PARA MUDAR COR DO BG MENU DESKTOP QUANDO SCROLLA*/
(function () {
  var menu = document.getElementById('header');
  window.addEventListener('scroll', function () {
      if (window.scrollY > 0) menu.classList.add('menu-demicron-auxliar');
      else menu.classList.remove('menu-demicron-auxliar');
  });
})();

document.querySelectorAll('#menu li a').forEach(item => {
    item.addEventListener('click', () => {
      const nav = document.getElementById('nav');
      nav.classList.remove('active')
    })
})